/* Global styles for body */
body {
  background-color: rgb(240, 246, 246);
}

.dark {
  background-color: #1a202c;
  color: #cbd5e0;
}

/* Animation styles for entering and exiting elements */
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 3000ms, transform 2000ms;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: opacity 2000ms, transform 2000ms;
}